/* eslint-disable react/no-string-refs */
import {Text} from "@blueprintjs/core";
import {Tooltip2} from "@blueprintjs/popover2";
import styled from "@emotion/styled";
import {CircleWarningIcon} from "./JobColHeaderRenderer";

export interface JobColHeaderRendererParams {
  responseErrorMessage: string | undefined;
}

const IgnoreMessages = [
  "Cannot generate ETA due to the manifest is empty",
  "Cannot generate ETA due to all stops are completed"
];

const getWarningMessage = (responseErrorMessage: string | undefined) => {
  if (!responseErrorMessage || IgnoreMessages.some((item) => responseErrorMessage.includes(item))) return "";

  if (responseErrorMessage.includes("no stops are completed"))
    return "Cannot generate ETA because no stops are completed.";

  if (responseErrorMessage.includes("contains completed stops that not sequential"))
    return "Cannot generate ETA due to the manifest contains completed stops that not sequential.";

  if (responseErrorMessage.includes("Route optimization request was invalid"))
    return "Cannot generate ETA due to the stop(s)' address being invalid.";

  return "Cannot generate ETA due to an unknown error.";
};

const ETAColHeaderRenderer = ({responseErrorMessage}: JobColHeaderRendererParams) => {
  const warningMsg = getWarningMessage(responseErrorMessage);
  return (
    <Container data-testid="eta-col-header">
      <Left>
        <Text ellipsize>ETA</Text>
        {warningMsg && (
          <Tooltip2 content={warningMsg} popoverClassName="manifest-header-warning-tooltip" placement="top">
            <CircleWarningIcon />
          </Tooltip2>
        )}
      </Left>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Left = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

export default ETAColHeaderRenderer;
