import {Address} from "../../../domain/geocode-types";
import {Manifest, Driver, Job, ManifestStopJob, ManifestFilter, ManifestStop} from "../../../generated/graphql";
import {ManifestSortOptions} from "../../../views/JobAssignmentViewReducer";
import {ManifestFilterState} from "../ManifestFilterState";
import {DetailViews} from "./ManifestDetailsContainer";

export type ETAStop = ManifestStop & {eta?: string | null; etaGeneratedDateTime?: string | null};

export type TExtraManifest = Manifest & {stops?: ETAStop[]; etaWarningMessage?: string};

type ManifestDetailsState = {
  disabledManifestIds: number[];
  selectedManifest?: TExtraManifest;
  selectedManifestDriver?: Driver;
  manifestDriverHomeAddress?: Address;
  selectedJobs?: (Job | ManifestStopJob)[];
  detailsViewType: DetailViews;
  manifestId?: number;
  assignDriverManifestFilter?: ManifestFilter;
  assignDriverManifestFilterState?: ManifestFilterState;
  assignDriverManifestSort?: ManifestSortOptions;
  assignDrvierManifestQuery?: string;
};

type ManifestDetailsActions =
  | {type: "SET_DISABLED_MANIFEST_IDS"; payload: number[]}
  | {type: "SET_SELECTED_MANIFEST"; payload: TExtraManifest | undefined}
  | {type: "SET_MANIFESET_ID"; payload: number}
  | {type: "SET_SELECTED_JOB"; payload: Job | ManifestStopJob | undefined}
  | {type: "SET_SELECTED_MANIFEST_DRIVER"; payload: Driver | undefined}
  | {type: "SET_MANIFEST_DRIVER_HOME_ADDRESS"; payload: Address | undefined}
  | {type: "SET_DETAILS_VIEW_TYPE"; payload: DetailViews}
  | {type: "SET_ASSIGNDRIVER_MANIFEST_FILTER"; payload: ManifestFilter}
  | {type: "SET_ASSIGNDRIVER_MANIFEST_FILTER_STATE"; payload: ManifestFilterState}
  | {type: "SET_ASSIGNDRIVER_MANIFEST_SORT"; payload: ManifestSortOptions}
  | {type: "SET_ASSIGNDRIVER_MANIFEST_QUERY"; payload: string}
  | {type: "CLEAR_ALL_STATES"};

const manifestDetailsReducer = (state: ManifestDetailsState, action: ManifestDetailsActions): ManifestDetailsState => {
  switch (action.type) {
    case "SET_DISABLED_MANIFEST_IDS":
      return {...state, disabledManifestIds: action.payload};
    case "SET_SELECTED_MANIFEST":
      return {...state, selectedManifest: action.payload};
    case "SET_MANIFESET_ID":
      return {...state, manifestId: action.payload};
    case "SET_SELECTED_MANIFEST_DRIVER":
      return {...state, selectedManifestDriver: action.payload};
    case "SET_MANIFEST_DRIVER_HOME_ADDRESS":
      return {...state, manifestDriverHomeAddress: action.payload};
    case "SET_DETAILS_VIEW_TYPE":
      return {...state, detailsViewType: action.payload};
    case "SET_ASSIGNDRIVER_MANIFEST_FILTER":
      return {...state, assignDriverManifestFilter: action.payload};
    case "SET_ASSIGNDRIVER_MANIFEST_FILTER_STATE":
      return {...state, assignDriverManifestFilterState: action.payload};
    case "SET_ASSIGNDRIVER_MANIFEST_SORT":
      return {...state, assignDriverManifestSort: action.payload};
    case "SET_ASSIGNDRIVER_MANIFEST_QUERY":
      return {...state, assignDrvierManifestQuery: action.payload};
    case "CLEAR_ALL_STATES":
      return {
        ...state,
        selectedManifest: undefined,
        selectedJobs: [],
        selectedManifestDriver: undefined,
        manifestId: undefined,
        disabledManifestIds: [],
        manifestDriverHomeAddress: undefined,
        detailsViewType: "NONE"
      };
    default:
      return state;
  }
};

export default manifestDetailsReducer;
export type {ManifestDetailsState, ManifestDetailsActions};
